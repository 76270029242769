/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState} from 'react'
import Modal from 'react-modal'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'gatsby'


const SuccessFormModal = ({modalIsOpen, setModalIsOpen, text}) => {
  
    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
        }
    }

    function closeModal(){
        setModalIsOpen(false)
    }
    
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Subscribe modal"
                ariaHideApp={false}
            >
                <div className="">
                    <div className="text-right">
                        <button onClick={closeModal}><CloseIcon/></button>
                    </div>  
                    <div className="w-full"> 
                        <h4 className="text-xl py-2 uppercase tracking-wider">Thanks!</h4>
                        <p className="w-full md:w-2/3 m-auto text-center flex mb-3">{text}</p>
                        <div className="my-4">
                            <Link to="/" onClick={closeModal} className="bg-mainColor text-white no-underline p-2 rounded-md tracking-widest cursor-pointer">Go back to homepage</Link>
                        </div>
                    </div>
                </div>
               
            </Modal>
        </div>
    )
}
export default SuccessFormModal