import React, { useState } from "react"
import Logo from "../assets/ricta-logo-white.svg"
import { Link } from "gatsby"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => setIsOpen(!isOpen)
  return (
    <div className="bg-mainColor border-gray-300 sticky top-0 z-99999 shadow">
      <div className="max-w-screen-xl sm:w-4/5 md:w-full m-auto">
        <nav className="flex items-center z-50 justify-between flex-wrap py-2 lg:px-5 bg-mainColor">
          <div className="flex justify-between lg:w-auto w-full border-solid border-gray-300 pb-0">
            <Link to="/" className="flex items-center justify-center pl-2">
              <Logo className="logo" alt="ricta logo" />
            </Link>
            <div className="block lg:hidden pr-2 flex items-center justify-center">
              <button
                onClick={() => handleClick()}
                id="nav"
                className="flex items-center z-50 px-2 p-2 border-2 border-white rounded text-white"
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
          </div>
          <div
            className={`menu w-full flex-grow lg:flex ${
              isOpen ? "block" : "hidden"
            } lg:items-center text-center lg:w-auto lg:px-3 px-8`}
          >
            <div className="text-lg leading-loose flex-grow sm:flex justify-end">
              <Link
                className="block lg:inline-block lg:mt-0 text-gray-200 no-underline hover:text-white px-4 py-2 mr-2"
                to="/#products"
                activeClassName="border-b-4 border-yellowColor"
              >
                Products
              </Link>
              <div className="dropdown inline-block relative text-gray-200 hover:text-white">
                <button className="inline-flex items-center px-4 py-2 mr-2">
                  <span className="mr-1">Services</span>
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </button>
                <ul className="dropdown-menu absolute hidden pt-1">
                  <li className="">
                    <Link
                      to="/trainings"
                      className="rounded-t no-underline text-black hover:text-mainColor py-2 px-4 block whitespace-no-wrap"
                      href="#"
                    >
                      Trainings
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/coaching"
                      className="rounded-t no-underline text-black hover:text-mainColor py-2 px-4 block whitespace-no-wrap"
                      href="#"
                    >
                      Coaching/Consultancy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}
export default Header
